$screen-xxs: 320px !default;
$screen-xs: 480px !default;
$screen-xs-max: 768px !default;
$screen-sm-max: 991px !default;
$screen-md-max: 1280px !default;
$screen-lg-max: 1440px !default;
$screen-max: 1980px !default;

$xs: "screen and (max-width: #{$screen-xxs})";
$max-xs: "screen and (max-width: #{$screen-xs-max - 1})";
$max-sm: "screen and (max-width: #{$screen-sm-max})";
$max-md: "screen and (max-width: #{$screen-md-max})";
$max-lg: "screen and (max-width: #{$screen-lg-max})";

$min-xs: "screen and (min-width: #{$screen-xs-max - 1})";
$min-sm: "screen and (min-width: #{$screen-sm-max + 1})";
$min-md: "screen and (min-width: #{$screen-md-max + 1})";
$min-lg: "screen and (min-width: #{$screen-lg-max + 1})";

$i: !important;

$font-size: 16px;

/*** EASES ***/
$ease-0: all 0.3s ease-in-out;
$ease-1: all 0.5s ease-in-out;

/*** COLORS ***/
$white: #ffffff;
$black: #000000;

$color-0: #beda1c;
$color-1: rgb(67,73,76);
$color-2: rgb(38, 42, 45);
$color-3: #eeeeee;
$color-4: #2684ff;


/*** FONTS ***/
$font-0: 'Roboto', sans-serif;

$font-color-0: #666666;
$font-color-1: #2f343a;
$color-link: #333;
