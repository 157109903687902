.dark-bg {
    background: $color-2;
    height: 50px;
}

.contentHeader {
    background: $color-1;
    padding-bottom: 50px;
    position: relative;

    @media #{$max-xs} {
        padding-bottom: 0;
    }

    &::before {
        content: ' ';
        background: url(../assets/img/header-element.png) no-repeat; 
        height: calc(100% + 25px);
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
        background-size: 100% 100%;
        max-width: 490px;

        @media #{$max-xs} {
            display: none;
        }
    }
}

.contentHeaderContain {
    background: $color-3;
    max-width: 986px;
    max-height: 150px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 15px;
    position: relative;
    box-sizing: border-box;
}

.sky-bg {
    max-width: 986px;
    margin: 0 auto;
    background-image: url(../assets/img/tumblr_n1ipt8kh8T1st5lhmo1_1280.jpg);
    background-size: 130%;
    background-position: center center;
    min-height: 150px;
}

.medium-bg {
    background: $color-1;
    position: absolute;
    height: 25px;
    top: -25px;
    width: 100%;
    left: 0;
}

.naviair-logo {
    max-width: 100%;
    min-height: 100px;
    height: auto;
    background-image: url(../assets/img/naviair-logo.png);
    background-repeat: no-repeat;
    margin-top: 20px;
    margin-left: 20px;

    @media #{$max-xs} {
        margin-top: 0;
        background-position: top right;
    }
}