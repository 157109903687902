.footerInfo {
    margin: 0;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: rgba(38, 42, 45, .5);
    z-index: 2;
    position: relative;
    padding: 0 0 15px;
    box-sizing: border-box;

    a {
        color: $white;
    }

    @media #{$max-xs} {
        background: $color-2;
        margin: 20px 0 0;
        align-items: center;

        span {
            text-align: center;
        }
    }
}

.footer {
    position: relative;
    background: $color-1;
    font-size: 8pt;
    line-height: 14px; 

    @media #{$max-xs} {
        height: auto;
    }

    &::after {
        content: ' ';
        background: url(../assets/img/header-element.png) no-repeat; 
        height: 144%;
        right: 0;
        position: absolute;
        top: -27px;
        width: 50%;
        background-size: 100% 100%;
        max-width: 490px;
        transform: rotate(180deg);

        @media #{$max-xs} {
            display: none;
        }
    }
}

.sectionWhite {
    display: flex;
    background: $white;
    z-index: 1;
    position: relative;
    max-width: 940px;
    margin: 0 auto;
    top: 45px;
    justify-content: space-between;
    padding: 15px 15px 35px;

    @media #{$max-xs} {
        top: 0;
        flex-direction: column;
        padding: 15px;
    }
}

.sectionCard {
    min-height: 120px;
    position: relative;
    flex: 0 0 23%;
    max-width: 25%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;

    @media #{$max-xs} {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }

    @for $i from 1 through 3 {
        &--#{$i} {
            background-image: url('../assets/img/section#{$i}.png');
        }
    }

    .linkAb {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-size: 0;
    }

    p {
        text-transform: uppercase;
        background: #dddddd;
        color: #2e2e2e;
        padding: 8px 5px;
        font-size: 14px;
        width: 100%;
        display: flex;
        box-sizing: border-box;
    }

    span {
        display: block;
        color: #2e2e2e;
        margin-top: 10px;
        font-size: 12px;

        a {
            color: #2e2e2e;
            text-decoration: none;
        }
    }
}

.linkMobile {
    display: none !important;
    @media #{$max-xs} {
        display: block !important;
        text-align: left;
        padding: 10px 0 10px;
        font-weight: 900;
    }
}