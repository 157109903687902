.alert {
    align-items: center;
    background: rgba(38, 42, 45, .8);
    bottom: 0;
    color: $white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    left: 0;
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    &--out {
        display: none;
        transform: translate(0, 175%);
        transition: transform 3s linear, display 1s linear 3s;
    }

    a {
        color: $white;
        display: inline;
        text-decoration: none;
    }
}

.closeAlert {
    background: url(../assets/img/close.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 5px;
    top: 6px;
    font-size: 0;
    cursor: pointer;
}

.alertContent {
    max-width: 940px;
    margin: 5% auto 0;
    text-align: left;
    position: relative;
    padding: 15px;
    background: white;
    color: $black;
    border-radius: 5px;
    box-shadow: 2px 3px 7px #989898;
    overflow: hidden;

    @media #{$max-xs} {
        max-width: 80%;
    }
    
    a {
        color: $black;
    }

    a {
        display: inline-block;
        margin: 0;
    }

    &--error {
        padding: 25px 15px 15px;
    }
    

    .btnAccept {
        display: flex;
        margin-top: 10px;
        padding: 5px 20px;
        border-radius: 5px;
        font-weight: bold;
        border: 1px solid #ccc;

        &:hover {
            transition: $ease-1;
            background: #ccc;
            border: 1px solid $black;
            box-shadow: 2px 3px 7px #989898;
        }
    }
}