.Error {
    display: flex;
    padding: 0 5px;
    color: #721c24;
    margin: 0 auto 15px;
    border-radius: 3px;
    width: 97%;
    box-sizing: content-box;

    @media #{$max-xs} {
        width: 100%;
    }

}

.invalid, .custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(169, 68, 66, .6) !important;
}

.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-check {
    display: flex;
}

.unavailable {
    cursor: not-allowed;

    input {
        cursor: not-allowed;
    }

    .react-datepicker-wrapper {
        pointer-events: none;
    }
}



.dangertAirspace {
    @media #{$max-xs} {
        margin: 15px 0 0;
    }   
}

.formGroupInput {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        font-weight: 900;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        i {
            margin-top: 0;
            font-weight: 100;
            line-height: 1.5;
        }
    }

    .customSelect {
        border-radius: 5px;
        @media #{$max-xs} {
            width: 104%;
        }
    }

    .form-group {
        margin-bottom: 10px !important;
    }

    .react-datepicker {
        @media #{$max-xs} {
            width: 108%;
        }
    }

    textarea,
    .inputText {
        width: 100%;
        min-height: 20px;
        padding: 8px 5px;
        border-radius: 5px;
        box-shadow: none;
        outline: none;
        border: 1px solid hsl(0,0%,80%);
        font-weight: normal;

        @media #{$max-xs} {
            width: 100%;
        }

        &:focus  {
            transition: border .5s ease-in-out;
        }
    }

    .css-1wa3eu0-placeholder {
        font-weight: normal;
    }

    &--checkBox {
        margin: 0 0 5px;

        @media #{$max-xs} {
            min-height: 38px;
        }

        input[type=checkbox].css-checkbox {
            position: absolute;
            z-index: -1000;
            left: -1000px;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;
        }
        
        input[type=checkbox].css-checkbox + label.css-label {
            padding-left: 20px;
            height: 15px;
            display: inline-block;
            line-height: 16px;
            font-weight: normal;
            background-repeat: no-repeat;
            background-position: 0 0;
            font-size: 15px;
            vertical-align: middle;
            cursor: pointer;
            font-weight: bold;

            &.hasWeapons,
            &.hasDGAut {
                margin: 5px 0 17px;
                
                @media #{$max-xs} {
                    margin: 5px 0 32px;
                }
            }

            &.classOne {
                margin: 0;
                @media #{$max-xs} {
                    margin: 0
                }
            }
        }
        
        input[type=checkbox].css-checkbox:checked + label {
            background-position: 0 -15px;
        }
        label.css-label {
            background-image: url(../assets/img/csscheckbox.png);
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }
    }
}

.checkboxIndent {
    margin: 5px 0 0 25px;
}

.col6Inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .sufix {
        position: relative;
        left: 33%;
    }

    .prefix,
    .sufix {
        margin-top: -10px;
    }

    .form-group{
        flex: 0 0 53%;
        margin: 0 2% 0;

        @media #{$max-xs} {
            flex: 0 0 40%;
            margin: 0;
        }

        &:first-child {
            flex: 0 0 30%;
            margin: 0;
        }
    }

    @media #{$max-xs} {
        width: 100%;
        flex-wrap: wrap;
    }

    .customSelect {
        min-width: 30%;
    }

    .inputText {
        max-width: 65%;
        margin-left: 15px;
        text-transform: uppercase;

        @media #{$max-xs} {
            max-width: 100%;
            margin: 10px 0 0;
        }
    }
}

.col2Inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .sufix {
        position: relative;
        left: 33%;
    }

    .prefix,
    .sufix {
        margin-top: -10px;
    }

    .form-group, label{
        flex: 0 0 67%;
        margin: 0;

        @media #{$max-xs} {
            flex: 0 0 40%;
            margin: 0;
        }

        &:first-child {
            flex: 0 0 30%;
            margin: 0;
        }
    }

    @media #{$max-xs} {
        width: 100%;
        flex-wrap: wrap;
    }

    .customSelect {
        min-width: 30%;
    }

    .inputText {
        max-width: 65%;
        margin-left: 15px;
        text-transform: uppercase;

        @media #{$max-xs} {
            max-width: 100%;
            margin: 10px 0 0;
        }
    }
}

%btnDefault {
    background: white;
    border-radius: 5px;
    font-size: 9pt;
    padding: 7px 17px;
    font-weight: bold;
    border: 1px solid hsl(0,0%,80%);

    &:hover,
    &:focus,
    &:active {
        transition: $ease-1;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
}

.contentForm {
    input[type="submit"] {
        background: white;
        border-radius: 5px;
        font-size: 9pt;
        padding: 7px 17px;
        font-weight: bold;
        border: 1px solid hsl(0,0%,80%);

        &:hover,
        &:focus,
        &:active {
            transition: $ease-1;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
    }

    .error {
        display: block;
    }
}

.resultContainer p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
}

.errorInput {
    .inputText:invalid {
        border: 1px solid red;
    }
}

.tableDG,
.tableRCS,
.tableRN {
    display: flex;
    height: 0;
    flex-direction: column;
    opacity: 0;
    transition: $ease-0;

    &.showTable {
        height: auto;
        opacity: 1;
    }
}

.titleTable {
    font-weight: bold;
    border-bottom: 1px solid;
}

.rowTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid;

    &:last-child {
        border-bottom: 0;
    }
}

.removeItem{
    @extend %btnDefault;
    max-height: 34px;
    min-width: 80px;
    cursor: pointer;
}

.addItem {
    @extend %btnDefault;
    max-height: 34px;
    min-width: 80px;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.dgForm {
    margin: 0 0 10px 20px;
}


.react-datepicker__day--keyboard-selected {
    background: #fff !important;
    color: #000 !important;
}

.react-datepicker__time-list-item--disabled,
.react-datepicker__time-list-item--selected .react-datepicker__time-list-item--disabled {
    background: #fff !important;
}
